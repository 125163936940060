import { Providers } from '../types/providers';
import { IRestApiAccessTokenPayload, IRestApiAccessTokenResponse } from '../types/restApi';
import BaseApiClient from './BaseApiClient';


class RestApiClient {

  public fetchApiAccessToken = async (countryCode: string, providerName: Providers, accessToken: string, idToken: string, tenantId: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "provider": providerName,
        "access_token": accessToken,
        "id_token": idToken,
        "tenant_id": tenantId
      }),
    };
    const res = await fetch(
      `https://${countryCode}-proxy-mt-01.preprod.incountry.io/validate/iamadmin/${providerName}/token`, requestOptions
    );

    return await res?.json();
  };

  public fetchAsgardeoAccessToken = async (): Promise<any> => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");
    urlencoded.append("scope", "internal_user_mgt_create internal_user_mgt_list internal_user_mgt_view internal_user_mgt_delete internal_user_mgt_update phone address email");
    
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Basic ${btoa('17fCXnZetT_G5oMIWCVkcT1zRbga' + ':' + 'cfE3YQI_4ey1aJKxj4PbEiEOeBZ4VzUcDKkQTWPavaMa')}`,
      },
      body: urlencoded
    };
    const res = await fetch(
      `https://api.asgardeo.io/t/orgoshs4/oauth2/token`, requestOptions
    );

    return await res?.json();
  };
}

const restApiClient = new RestApiClient();

export default restApiClient;
