class BorderApiClient {

  public fetchUsers = async (countryCode: string, tenantId: string, token: string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };
    const res = await fetch(
      `https://${countryCode}-proxy-mt-01.preprod.incountry.io/x-inc-${tenantId}/scim2/Users`, requestOptions
    );

    return await res?.json();
  };
}

const borderApiClient = new BorderApiClient();

export default borderApiClient;
